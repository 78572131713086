import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import { graphql } from 'gatsby'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import Container from '../components/Container'
import CallToActionBanner from '../components/CallToActionBanner/CallToActionBanner'
import ServicesContent from '../components/ServicesContent/ServicesContent'
import ServicesHero from '../components/ServicesHero/ServicesHero'
import SEO from '../components/SEO'

const Services = ({ data }) => {
  const postNode = {
    title: `Services - ${config.siteTitle}`,
  }
  const posts = data ? data.allContentfulPost.edges : []

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="Insights" customTitle />
      <Helmet>
        <title>{`Services - ${config.siteTitle}`}</title>
      </Helmet>
      <ServicesHero />
      <Container color={'light-grey'}>
        <ServicesContent posts={posts} />
      </Container>
      <CallToActionBanner
        linkText={'View our Case Studies'}
        route={'/insights?filter=casestudies'}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 100
    ) {
      edges {
        node {
          title
          id
          type
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Services
