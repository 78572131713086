import React from 'react'
import './ServicesCard.scss'
import { Link } from 'gatsby'

export class ServicesCard extends React.Component {
  render() {
    const { name, icon, description, slug, id } = this.props
    return (
      <Link style={{ textDecoration: 'none' }} key={name} to={'/' + slug + '/'}>
        <div id={id} className="services-card">
          <div className="image-wrap">
            <img src={icon} />
          </div>
          <div className="text">
            <h2 className="title">{name}</h2>
            <div className="description">{description}</div>
            <div className="buffer"/>
            <div className="cta-row">
              <button className="btn-outline-light-blue mini">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default ServicesCard
