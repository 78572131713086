import React from 'react'
import './ServicesContent.scss'
import ServicesCard from '../ServicesCard/ServicesCard'
// import ClientMatrix from '../ClientMatrix/ClientMatrix'

export class ServicesContent extends React.Component {
  render() {
    return (
      <div className="services-content">
        <div className="row">
          <div className="col-sm-4">
            <ServicesCard
              name={'DevSecOps'}
              id={'cloudsecops'}
              slug={'cloudsecops'}
              icon={'/cloudsecops_icon.svg'}
              description={`Accelerate application delivery, scalability, and security.`}
            />
          </div>
          <div className="col-sm-4">
            <ServicesCard
              name={'AI & Platform Development'}
              id={'digital-agility'}
              slug={'digitalagility'}
              icon={'/digital_icon.svg'}
              description={`Develop or modernize business-critical 
              applications with AI Capabilities`}
            />
          </div>
          <div className="col-sm-4">
            <ServicesCard
              id={'mobile-iot-vr'}
              description={`Build the next generation of innovative
                 systems with emerging technologies`}
              slug={'mobile'}
              name={'Edge, IoT/Mobile, and VR'}
              icon={'/mobile_icon.svg'}
            />
          </div>
        </div>
        {/* <ClientMatrix /> */}
      </div>
    )
  }
}

export default ServicesContent
